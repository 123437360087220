<template>
  <div class="add">
    <div class="add_btn">
      <el-button
        type="primary"
        @click="onAdd">
        新增服务
      </el-button>
    </div>
    <div class="add_list tw-flex tw-flex-wrap">
      <div
        class="add_list_item item"
        v-for="(item,index) in value"
        :key="index"
        @click="onEdit(item, index)">
        <div class="item_header tw-flex tw-items-center tw-justify-between">
          <p class="tw-text-18px tw-font-bold">
            {{index + 1}}.{{getServiceType(item.serviceTypeId).parent}}--{{getServiceType(item.serviceTypeId).child}}
          </p>
          <i
            class="item_header_icon iconfont iconchacha"
            @click.stop="onDelete(index)"/>
        </div>
        <div class="item_body tw-flex tw-items-center">
          <p class="tw-text-14px item_body_subtype">
            {{getServiceType(item.serviceTypeId).grandChild}}
            <span>{{getServiceTitle(item.serviceId)}}</span>
          </p>
        </div>
      </div>
    </div>

    <el-dialog
      :title="editIndex === -1 ? '新增服务' : '修改服务'"
      :visible.sync="dialogVisible"
      width="600px"
      :show-close="false"
      @close="onClose">
      <el-checkbox-group
        v-model="check"
        :max="1">
        <el-tabs v-model="activeTab">
          <el-tab-pane
            label="我的服务"
            name="1">
            <div class="checkbox">
              <div class="checkbox_header">
                <el-input maxlength="50" show-word-limit
                  v-model="title"
                  placeholder="请输入服务名称"
                  :clearable="true"
                  @keyup.native.enter="getServiceList(1)">
                </el-input>
              </div>
              <div style="border-bottom: 1px solid #DCDFE6;">
                <div
                  class="checkbox_item"
                  style="padding-left:25px;"
                  v-for="item in serviceList"
                  :key="item.id">
                  <p class="checkbox_item_label tw-text-14px">
                    {{item.title}}
                  </p>
                  <el-checkbox :label="item.id">
                    <span></span>
                  </el-checkbox>
                </div>
              </div>
              <div class="checkbox_pagination tw-flex tw-justify-center tw-items-center">
                <el-pagination
                  hide-on-single-page
                  background
                  @current-change="onPageNumChange"
                  :current-page.sync="pagination.pageNum"
                  :page-size="pagination.pageSize"
                  layout="prev, pager, next, jumper"
                  :total="pagination.total">
                </el-pagination>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane
            label="其他服务"
            name="2">
            <div class="checkbox">
              <el-collapse accordion>
                <el-collapse-item
                  class="checkbox-collapse-item"
                  v-for="item in serviceTypeList"
                  :key="item.id"
                  :title="item.name"
                  :name="item.id">
                  <el-collapse accordion>
                    <el-collapse-item
                      class="checkbox-collapse-sub-item"
                      v-for="subItem in item.children"
                      :key="subItem.id"
                      :title="subItem.name"
                      :name="subItem.id">
                      <div
                        class="checkbox_item"
                        v-for="service in subItem.children"
                        :key="service.id">
                        <p class="checkbox_item_label tw-text-14px">
                          {{service.name}}
                        </p>
                        <el-checkbox :label="service.id">
                          <span></span>
                        </el-checkbox>
                      </div>
                    </el-collapse-item>
                  </el-collapse>
                </el-collapse-item>
              </el-collapse>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-checkbox-group>
      <div slot="footer">
        <el-button
          size="small"
          @click="onCancel">
          取消
        </el-button>
        <el-button
          type="primary"
          size="small"
          @click="onConfirm">
          确认选择
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as api from '@/api'

export default {
  props: {
    value: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      dialogVisible: false,
      activeTab: '1',
      editIndex: -1,
      check: [],
      title: '',
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      serviceList: [],
      serviceTypeList: []
    }
  },
  computed: {
    getServiceTitle () {
      return (id) => {
        let title = ''

        this.serviceList.forEach(element => {
          if (element.id === id) {
            title = element.title
          }
        })
        return title
      }
    },
    getServiceType () {
      return (id) => {
        let parent = ''
        let child = ''
        let grandChild = ''

        this.serviceTypeList.forEach(parentItem => {
          parentItem.children.forEach(childItem => {
            childItem.children.forEach(grandChildItem => {
              if (grandChildItem.id === id) {
                parent = parentItem.name
                child = childItem.name
                grandChild = grandChildItem.name
              }
            })
          })
        })

        return { parent, child, grandChild }
      }
    }
  },
  mounted () {
    this.getServiceList()
    this.getServiceTypeList()
  },
  methods: {
    onAdd () {
      this.dialogVisible = true
    },
    getServiceList (pageNum) {
      api.getMyServiceList({
        pageNum,
        pageSize: this.pagination.pageSize,
        title: this.title,
        logicStatus: this.globalVar.SERVICE_STATUS_1
      }).then(res => {
        if (res.data.code === 0) {
          this.pagination.pageNum = res.data.data.currentPage
          this.pagination.total = res.data.data.total
          this.serviceList = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.loading = false
      })
    },
    getServiceTypeList () {
      if (this.serviceTypeList.length > 0) return
      api.getServiceTypeCheckList().then(res => {
        if (res.data.code === 0) {
          this.serviceTypeList = res.data.data
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      })
    },
    onPageNumChange (page) {
      this.serviceList.forEach(element => {
        if (element.id === this.check[0]) {
          this.check = []
        }
      })

      this.getServiceList(page)
    },
    onClose () {
      this.check = []
    },
    onCancel () {
      this.dialogVisible = false
    },
    onConfirm () {
      if (this.check.length < 1) return

      const id = this.check[0]
      let serviceTypeId = ''
      let serviceId = ''

      this.serviceTypeList.forEach(parentItem => {
        parentItem.children.forEach(childItem => {
          childItem.children.forEach(grandChildItem => {
            if (grandChildItem.id === id) {
              serviceTypeId = id
            }
          })
        })
      })

      this.serviceList.forEach(element => {
        if (element.id === id) {
          serviceTypeId = element.serviceTypeId
          serviceId = id
        }
      })

      if (this.editIndex === -1) {
        this.$emit('input', [
          ...this.value,
          {
            serviceTypeId,
            serviceId
          }
        ])
      } else {
        const array = this.$clone(this.value)
        array.splice(this.editIndex, 1, {
          serviceTypeId,
          serviceId
        })

        this.$emit('input', [...array])
      }
      this.editIndex = -1
      this.dialogVisible = false
    },
    onEdit (item, index) {
      this.editIndex = index
      if (item.serviceId) {
        this.check = [item.serviceId]
      } else {
        this.check = [item.serviceTypeId]
      }
      this.dialogVisible = true
    },
    onDelete (index) {
      const array = this.$clone(this.value)
      array.splice(index, 1)
      this.$emit('input', [...array])
    }
  }
}
</script>

<style lang="scss" scoped>
.add {
  &_btn {
    margin-bottom: 20px;
  }
  &_list {
    margin: -10px;
    &_item {
      background: #FFFFFF;
      border: 1px solid #DCDFE6;
      border-radius: 4px;
      &:hover {
        cursor: pointer;
        border: 1px solid #3473E6;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
      }
    }
    .item {
      width: 320px;
      height: 90px;
      margin: 10px;
      &_header {
        height: 40px;
        padding: 0 17px 0 10px;
        background-image: url('../../../assets/images/service_org_bg.png');
        background-size: 270px 40px;
        background-repeat: no-repeat;
        background-position: top right;
        &_icon {
          color: #F36A6E;
          &:hover {
            color: rgba($color: #F36A6E, $alpha: 0.8);
          }
        }
      }
      &_body {
        height: 50px;
        padding-left: 20px;
        &_subtype {
          line-height: 20px;
          color: #C0C4CC;
          & > span {
            margin-left: 10px;
            color: #606266;
          }
        }
      }
    }
  }
}

.checkbox {
  height: 420px;
  padding: 10px;
  overflow: auto;
  border: 1px solid #DCDFE6;
  border-radius: 2px;
  &_header {
    height: 60px;
    margin: -10px -10px 10px -10px;
    padding: 14px 10px;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  }
  &_pagination {
    margin: 20px 0;
    height: 40px;
  }
  &_item {
    height: 50px;
    padding: 0 25px 0 45px;
    border-top: 1px solid #DCDFE6;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

}
</style>

<style lang="scss">
.checkbox-collapse-item {
  .el-collapse-item__header {
    font-size: 14px;
    padding: 0 25px 0 10px;
  }
  .el-collapse-item__wrap .el-collapse-item__content {
    padding-bottom: 0;
  }
}
.checkbox-collapse-sub-item {
  .el-collapse-item__header {
    padding-left: 25px;
  }
}
</style>
